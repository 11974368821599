import React from 'react';
import { ProgressProps } from './ProgressTypes';
import { StyledProgress, StyledProgressLine } from './ProgressStyled';

import { Text } from '../Text';
import colors from '../../constants/colors';

export const Progress = ({ value, label }: ProgressProps) => {
  return (
    <StyledProgress>
      <StyledProgressLine $value={value} />
      <Text $textStyle='p10' $color={colors.grey2}>
        {label}
      </Text>
    </StyledProgress>
  );
};
