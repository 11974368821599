import React from 'react';
import { Box } from '../../Box';
import { Text } from '../../Text';
import linkIcon from '../../../../../assets/images/link.svg';

export const VideoCheck = (props: any) => {
  const { user, VIDEO_REWRITED_KEY, colors } = props;

  const userInfoUrl = () => {
    if (user?.info) {
      const url = user.info.indexOf('http') !== 0 ? `http://${user.info.trim()}` : user.info;
      window.open(url, '_blank');
    }
  };

  return (
    <>
      {!user?.variables?.[VIDEO_REWRITED_KEY] && (
        <Box $cursor='pointer' $align={'center'} $mT={'20px'} onClick={userInfoUrl}>
          <Text $textStyle='p18' $color={colors.red}>
            Проверить доступность
          </Text>
          <img src={linkIcon} alt='' />
        </Box>
      )}
    </>
  );
};
