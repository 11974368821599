import React from 'react';
import { Box } from '../../Box';
import { Button } from '../../Button';

export const VideoEdit = (props: any) => {
  const { user, VIDEO_REWRITED_KEY, resetLink } = props;

  // const resetLink = useCallback(() => {
  //   setIsAttached(false);
  // }, []);

  return (
    <>
      {!user?.variables?.[VIDEO_REWRITED_KEY] && (
        <Button $textStyle='p18' $fontWeight='500' $mT='20px' onClick={resetLink} $style='ghost'>
          <Box $justify='space-between' $align='center'>
            Изменить
          </Box>
        </Button>
      )}
    </>
  );
};
