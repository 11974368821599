import styled from 'styled-components';

import { colors } from '../../constants/colors';

export const StyledProgress = styled.div``;

export const StyledProgressLine = styled.div<{ $value: number }>`
  height: 4px;
  position: relative;

  margin-bottom: 2px;

  &:after,
  &:before {
    top: 0;
    left: 0;

    content: '';

    width: 100%;
    height: 100%;

    position: absolute;
    border-radius: 12px;
  }

  &:after {
    transition: all 250ms;
    background: ${colors.blueShadow};
    ${({ $value }) => `width: ${$value * 100}%;`};
  }

  &:before {
    background: rgba(128, 129, 145, 0.1);
  }
`;
