import React from 'react';
import { IconUploadFile } from '../../../../assets/icons/IconUploadFile';
import { Box } from '../Box';
import { Text } from '../Text';

export interface EmptyFileProps {
  colors: any;
  $size: 'small' | 'large';
  description: string | undefined;
}

export const EmptyFile = (props: EmptyFileProps) => {
  const { colors, $size, description } = props;

  return (
    <Box $justify='center' $align='center' $direction='column'>
      <IconUploadFile colors={colors} />
      <Text
        $textAlign='center'
        $textStyle='p13'
        $mT={$size === 'small' ? '5px' : '12px'}
        $maxW='320px'
        $color={colors.grey2}>
        {description}
      </Text>
    </Box>
  );
};
