import { VideoCheck } from './condition/VideoCheck';
import { VideoEdit } from './condition/VideoEdit';
import { VideoIsAttached } from './condition/VideoIsAttached';

import { СonclusionText } from './conclusionText';
import { IntroductionText } from './introductionText';

export {
  HeaderModalListStyled,
  HeaderModalVideoText,
  StyledModalAttachmentVideoStyled,
} from './ModalAttachmentVideoStyled';

export { VideoCheck, VideoEdit, VideoIsAttached };

export { СonclusionText, IntroductionText };
