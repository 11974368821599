import React from 'react';

const IconCross: React.FC = () => (
  <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3.66669 18.3333L11 11M18.3334 3.66666L11 11M11 11L18.3334 18.3333M11 11L3.66669 3.66666'
      stroke='#808191'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default IconCross;
