import React from 'react';
import IconCross from '../../../../assets/icons/IconCross';
import { Box } from '../Box';
import { Progress } from '../Progress';
import { Text } from '../Text';
import { StyledFileItemProgress } from '../../../../seventhGrade/components/UploadFiles/ui/UploadFilesStyled';

export interface UploadProgressProps {
  loadedFileLink: any;
  colors: any;
  file: any;
  progress: any;
  onDelete: any;
}

export const UploadProgress = (props: UploadProgressProps) => {
  const { loadedFileLink, colors, file, progress, onDelete } = props;

  return (
    <Box $align='center'>
      <Box $isFullWidth={false} $mR='10px' $cursor={'pointer'} onClick={onDelete}>
        <IconCross />
      </Box>
      <StyledFileItemProgress>
        <Box $justify='space-between'>
          <Text
            $textStyle='p13'
            $color={loadedFileLink ? colors.text : colors.grey2}
            $fontWeight='500'>
            {file.name}
          </Text>
          {!loadedFileLink && (
            <Text $textStyle='p13' $color={colors.grey2}>
              Загрузка...
            </Text>
          )}
        </Box>
        {!loadedFileLink && <Progress value={progress / 100} label='' />}
      </StyledFileItemProgress>
    </Box>
  );
};
