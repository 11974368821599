/* eslint-disable max-len */
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

import FileSaver from 'file-saver';
import { FilledFileProps, UploadFilesProps } from '../types';
import {
  StyledUploadFiles,
  StyledDropDownZone,
  StyledFileItem,
  StyledFileType,
  StyledFileWrapper,
  StyledDownloadInstructions,
} from './UploadFilesStyled';

import { Text } from '../../../../common/shared/ui/Text';
import colors from '../../../../common/shared/constants/colors';
import { Box } from '../../../../common/shared/ui/Box';
import FilesApi from '../../../api/files';
import { Progress } from '../../../../common/shared/ui/Progress';
import { Button } from '../../../../common/shared/ui/Button';
import axios from 'axios';

import { UploadProgress } from './../../../../common/shared/ui/UploadFiles';
import { EmptyFile } from './../../../../common/shared/ui/UploadFiles';
import IconDownload from '../../../../assets/icons/IconDownload';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserFiles, selectUserInfo } from '../../../redux/user/userGetters';
import userSlice from '../../../redux/user';
import UserApi from '../../../api/user';

const { setChallangeFlag } = userSlice.actions;

let loadedFileId = ''; // сгенерированный на бэке id
let loadedFile: any = null;
let loadedChallengeLink = ''; // сслыка на загруженный файл с челленджем

const FilledFile = (props: FilledFileProps) => {
  const { uploadLink, type, file, isUploadedFile, onDelete, onLoadFile, fileType } = props;

  const [loadedFileLink, setLoadedFileLink] = useState('');
  const [challangeID, setChallangeID] = useState('');
  const [progress, setProgress] = useState(0);
  const isCallbackSent = useRef(false);
  const userInfo = useSelector(selectUserInfo);

  useEffect(() => {
    if (loadedFileLink && !isCallbackSent.current) {
      isCallbackSent.current = true;
      onLoadFile(loadedFileLink);
    }
  }, [onLoadFile, loadedFileLink]);

  const onProgress = useCallback(
    (link: string) => (data: any) => {
      const percent = Math.round((data.loaded / data.total) * 100);
      setProgress(percent);

      if (percent === 100) {
        setLoadedFileLink(link);
      }
    },
    [],
  );

  useEffect(() => {
    const source = axios.CancelToken.source();

    (async () => {
      //const result = await FilesApi.getUploadLink(file.name);
      //const uploadLink = result.data.url;
      const { REACT_APP_API_BASE_URL } = process.env;

      const uploadUrl = REACT_APP_API_BASE_URL + uploadLink; // /files - для видео   /documents - для челенджей

      if (uploadUrl) {
        const cancelToken = source.token;
        const link = uploadUrl.split('?')[0];

        try {
          const uploadedFile = await FilesApi.uploadFile(
            userInfo?.id,
            file,
            uploadUrl,
            cancelToken,
            onProgress(link),
            fileType,
          );

          if (uploadedFile) {
            // загрузка видео на сервер и все
            loadedFileId = uploadedFile.data.id;
            loadedFile = uploadedFile.data;
            //setLoadedFile(uploadedFile.data)
            setProgress(100);

            loadedChallengeLink = loadedFile.url;
            setChallangeID(loadedFileId);
            setLoadedFileLink(loadedFile.url);
          } else {
            onDelete();
          }
        } catch (error: any) {
          console.error(error);
          if (error.message !== 'cancel loading') {
            onDelete();
            alert('Ошибка загрузки файла');
          }
        }
      }
    })();

    return () => {
      if (!loadedFileLink) {
        source.cancel('cancel loading');
      }
    };

    // потому что didmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const splitName = file.name?.split('.');
  const splitType = file.type?.split('/');
  const extensionFromName = splitName.length > 1 ? splitName[splitName.length - 1] : '';
  const extensionFromType = splitType.length > 1 ? splitType[splitType.length - 1] : '';

  const extension = (extensionFromName || extensionFromType || 'txt').toLowerCase();

  if (type === 'progress') {
    return (
      <UploadProgress
        loadedFileLink={loadedFileLink}
        colors={colors}
        file={file}
        progress={progress}
        onDelete={onDelete}
      />
    );
  }

  return (
    <StyledFileWrapper>
      <StyledFileItem>
        <Text
          $withTextOverflow
          $textAlign='center'
          $textStyle='p12'
          $mT='6px'
          $mB='12px'
          $color={colors.grey2}>
          {file.name}
        </Text>
        {!loadedFileLink && <Progress value={progress / 100} label={`Загружено ${progress}%`} />}
        <StyledFileType>.{extension}</StyledFileType>
      </StyledFileItem>
      {!isUploadedFile && (
        <Box onClick={onDelete} $cursor={'pointer'}>
          <Text
            $withTextOverflow
            $textAlign='center'
            $textStyle='p13'
            $mT='6px'
            $color={colors.red}>
            удалить
          </Text>
        </Box>
      )}
    </StyledFileWrapper>
  );
};

const UploadFiles = (props: UploadFilesProps) => {
  const [file, setFile] = useState<File | null>(null);
  const [isDragging, setDraggingState] = useState(false);
  const [isUploadedFile, setUploadedStateFile] = useState(false);
  const [loadedFileLink, setLoadedFileLink] = useState('');

  const { onSaveHandler, onBackendResponse, isDisabled = false } = props;
  const { setChallangeFlag, setUserFiles } = userSlice.actions;

  const dispatch = useDispatch();

  // запрос на удаление в бэке
  const deleteOnBackend = () => {
    FilesApi.deleteFile(loadedFileId);
  };

  const onDeleteFile = useCallback((e?: any) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setFile(null);
    if (loadedFileId) {
      deleteOnBackend();
      loadedFileId = '';
      loadedFile = '';
    }
    if (onBackendResponse) {
      onBackendResponse(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLoadFileCb = props.onLoadFile;

  const onLoadFile = useCallback(
    (link: string) => {
      setLoadedFileLink(link);

      if (file) {
        onLoadFileCb?.(link, file.type);
        if (onBackendResponse) {
          setTimeout(() => {
            setLoadedFileLink(loadedFile);
            onBackendResponse(loadedFile);
          }, 4000);
        }
      }
    },
    [file, onLoadFileCb],
  );

  const onDragEnter = useCallback((e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDraggingState(true);
  }, []);

  const onDragLeave = useCallback((e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDraggingState(false);
  }, []);

  const onDragOver = useCallback((e: any) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const fileSizeLimit = props.fileType === 'presentation' ? 50 : 10; // макс допустимый размер файла в MB

  /**
   * возвращает true, если файл подходит по размеру, иначе false
   * @param file
   */
  const checkFileSize = (file: any) => {
    let res = true;

    const size = file.size / 1024 / 1024; // размер файла в MB

    if (size > fileSizeLimit) {
      res = false;
    }

    return res;
  };

  const onDrop = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();

      setDraggingState(false);

      if (e?.dataTransfer?.files && e?.dataTransfer?.files.length) {
        try {
          let newFile = undefined;

          const accept = props.accept?.replace(/\*|\./gim, '').split(',');

          for (let i = 0; i < e?.dataTransfer?.files.length; i++) {
            const curFile = e?.dataTransfer?.files[i];

            if (accept && accept.length) {
              let condition = false;

              for (let j = 0; j < accept.length; j++) {
                const checkExtensions = accept[j];
                const extension = curFile.name.split('.');

                if (
                  curFile.type.indexOf(checkExtensions) !== -1 ||
                  extension[extension.length - 1].indexOf(checkExtensions) !== -1
                ) {
                  condition = true;
                }
              }

              if (condition) {
                newFile = curFile;
                break;
              }
            }
          }

          if (newFile) {
            const sizeIsGood = checkFileSize(newFile);
            if (isDisabled) return;
            if (sizeIsGood) {
              setFile(newFile);
            } else {
              alert(`Размер файла не должен превышать ${fileSizeLimit}MB`);
            }
          } else {
            alert(
              'Не найдено подходящего файла. Попробуйте нажать на зону загрузки для вызова контекстого меню выбора файла или попробуйте загрузить другой файл',
            );
          }
        } catch (error) {
          alert('Произошла ошибка при обработке файла. Загрузите файл, кликнув на зону загрузки');
        }
      }
    },
    [props.accept],
  );

  const onClickDropDownZone = useCallback(() => {
    const onSelectedFiles = (event: ChangeEvent<HTMLInputElement>) => {
      if (event?.target?.files && event?.target?.files.length) {
        const sizeIsGood = checkFileSize(event?.target?.files[0]);
        if (isDisabled) return;
        if (sizeIsGood) {
          setFile(event?.target?.files[0]);
        } else {
          alert(`Размер файла не должен превышать ${fileSizeLimit}MB`);
        }
      }
    };

    const input = document.createElement('input');

    input.type = 'file';
    input.multiple = false;
    input.accept = props.accept || '*';
    input.onchange = onSelectedFiles as any;

    input.click();
  }, [props.accept]);

  const onSave = useCallback(async () => {
    if (file) {
      try {
        if (onSaveHandler) {
          const cancel = await onSaveHandler(loadedChallengeLink);
          const response = await FilesApi.postChallangeToUser(loadedFileId);
          const userFiles = await UserApi.setUserFiles();

          if (response.status === 200) {
            await dispatch(setChallangeFlag(true));
            await dispatch(setUserFiles(userFiles.data));
          }
          if (!cancel) {
            setUploadedStateFile(true);
          }
        } else {
          // функция загрузки видео к пользователю
          // await UserApi.setUserChallenge('loadedFileLink', file.type);
          setUploadedStateFile(true);
        }
      } catch (error) {
        alert('Ошибка сохранения файла');
        console.log(error);
      }
    }
  }, [loadedFileLink, onSaveHandler, file]);

  const downloadInstruction = useCallback(() => {
    if (props.instruction) {
      FileSaver.saveAs(props.instruction.url, props.instruction.title);
    }
  }, [props.instruction]);

  useEffect(() => {
    if (!file) {
      setUploadedStateFile(false);
      setLoadedFileLink('');
    }
  }, [file]);

  return (
    <StyledUploadFiles $wrapperPadding={props.$wrapperPadding}>
      {!props.withoutTitle && (
        <Box $justify='space-between'>
          <Text
            $textStyle={props.$size === 'small' ? 'p14' : 'p18'}
            $color={colors.title}
            $fontWeight={props.$size === 'small' ? '600' : '800'}>
            {isUploadedFile ? 'Файл отправлен' : 'Загрузка файлов'}
          </Text>
          {props.instruction && (
            <StyledDownloadInstructions>
              <Text
                $mR='8px'
                $textStyle='p13'
                onClick={downloadInstruction}
                $color={colors.red}
                $fontWeight='500'>
                {/* Скачать файл для заполнения */}
              </Text>
              {/* <IconDownload /> */}
            </StyledDownloadInstructions>
          )}
        </Box>
      )}

      {file ? (
        <FilledFile
          uploadLink={props.uploadLink || '/files'}
          type={props.type || 'default'}
          isUploadedFile={isUploadedFile}
          file={file}
          onDelete={onDeleteFile}
          onLoadFile={onLoadFile}
          fileType={props.fileType}
        />
      ) : (
        <StyledDropDownZone
          onDrop={onDrop}
          onDragOver={onDragOver}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onClick={onClickDropDownZone}
          $size={props.$size || 'large'}
          $isDragging={isDragging}
          $isEmptyList={!file}>
          <EmptyFile
            colors={colors}
            $size={props.$size || 'large'}
            description={props.description}
          />
        </StyledDropDownZone>
      )}

      {!props.hideSaveButton && file && !isUploadedFile && (
        <Button
          disabled={!loadedFileLink}
          $maxW='120px'
          $minW='120px'
          $mT={'12px'}
          $style='blue'
          onClick={onSave}>
          <Box $justify='center' $isWrap={false} $direction='row'>
            Отправить
          </Box>
        </Button>
      )}
    </StyledUploadFiles>
  );
};

export default UploadFiles;
