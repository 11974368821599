import styled, { css, keyframes } from 'styled-components';
import colors from '../../../../common/shared/constants/colors';

import { UploadFilesProps } from '../types';
import { BREAKPOINT_PHONE } from '../../../../common/shared/constants/constants';

const dragAndDropAnimation = keyframes`
  0% {
    border-color: ${colors.grey2};
  }
  50% {
    border-color: ${colors.blueButton};
  }
  100% {
    border-color: ${colors.grey2};
  }
`;

export const StyledDeleteFileItem = styled.div`
  top: 0;
  right: 0;
  z-index: 10;
  position: absolute;
  transform: translate(50%, -50%);

  width: 24px;
  height: 24px;
  border-radius: 24px;
  background: ${colors.red};
`;

export const StyledFileWrapper = styled.div`
  width: 104px;
  position: relative;
`;

export const StyledFileItem = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;

  padding: 8px 8px 8px;
  width: 100%;
  height: 130px;
  border-radius: 4px;
  border: 1px solid #808191;
`;

export const StyledFileItemProgress = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  & > div {
    width: 100%;

    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

export const StyledFileType = styled.div`
  z-index: 1;
  position: absolute;

  left: 0;
  bottom: 12px;
  padding: 3px 12px 3px 18px;

  font-size: 13px;
  color: ${colors.white};
  background: ${colors.grey2};
`;

export const StyledUploadFiles = styled.div<UploadFilesProps>`
  width: 100%;

  padding: ${(props) => (props.$wrapperPadding ? props.$wrapperPadding : '20px')};
  border-radius: 16px;
  background: ${colors.white};

  & > * + * {
    margin-top: 10px;
  }

  @media screen and (min-width: ${BREAKPOINT_PHONE}) {
    padding: ${(props) => (props.$wrapperPadding ? props.$wrapperPadding : '28px')};

    & > * + * {
      margin-top: 15px;
    }
  }
`;

export const StyledDropDownZone = styled.div<{
  $isEmptyList: boolean;
  $isDragging: boolean;
  $size: 'small' | 'large';
}>`
  border-radius: ${({ $size }) => ($size === 'small' ? '8px' : '12px')};
  border: 2px dashed ${colors.grey2};
  height: 500px;

  cursor: pointer;

  transition-duration: 250ms;
  transition-property: background, border-color;

  ${({ $isDragging }) =>
    $isDragging &&
    css`
      background: rgba(0, 0, 0, 0.2);
      animation: ${dragAndDropAnimation} 1.5s infinite;

      * {
        pointer-events: none;
      }
    `}

  ${({ $isEmptyList, $size }) =>
    $isEmptyList
      ? css`
          padding: ${$size === 'small' ? '12px 0px 16px 0px' : '12px'};
          height: ${$size === 'small' ? '120px' : '200px'};
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
        `
      : css`
          display: flex;
          flex-wrap: wrap;
          padding: 0 12px 12px 0;

          ${StyledFileWrapper} {
            margin-left: 12px;
            margin-top: 12px;
          }
        `};
`;

export const StyledDownloadInstructions = styled.div`
  display: flex;

  cursor: pointer;
`;
